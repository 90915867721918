<template>
    <nav class="">
        <div class="flex flex-col items-start relative">
            <InertiaLink href="/players/me/rounds">
                <SwingULogo />
            </InertiaLink>
        </div>
        <ul class="flex flex-grow ml-5">
            <li class="mr-5">
                <InertiaLink
                    href="/players/me/rounds"
                    :class="{
                        'border-b-[3px] border-b-[var(--medium-primary)] pb-6':
                            $page.component === 'rounds/list',
                    }"
                    class="relative h-4 hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out"
                >
                    Rounds
                </InertiaLink>
            </li>
            <li class="mr-5">
                <InertiaLink
                    href="/players/me"
                    :class="{
                        'border-b-[3px] border-b-[var(--medium-primary)] pb-6':
                            $page.component ===
                            'player/stats/advanced/aggregate-index',
                    }"
                    class="relative h-4 hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out"
                >
                    Advanced Stats
                </InertiaLink>
            </li>
            <li class="mr-5">
                <InertiaLink
                    href="/players/me/stats/basic"
                    :class="{
                        'border-b-[3px] border-b-[var(--medium-primary)] pb-6':
                            $page.component === 'player/stats/basic/basic-index',
                    }"
                    class="relative h-4 hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out"
                >
                    Basic Stats
                </InertiaLink>
            </li>
            <li>
                <!-- This needs to use <a> instead of InertiaLink to avoid CORS issues in some edge cases -->
                <a
                    href="/players/me/clubs"
                    :class="{
                        'border-b-[3px] border-b-[var(--medium-primary)] pb-6':
                            $page.component === 'legacy/Players/clubs/list-clubs' || $page.component === 'legacy/Players/clubs/tracked-club',
                    }"
                    class="relative h-4 hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out"
                >
                    Clubs
                </a>
            </li>
        </ul>
        <ul class="flex place-content-end">
            <!-- <li class="mr-5 flex flex-row items-center group">
                <InertiaLink
                    href="/round"
                    class="flex h-4 items-center p-0 gap-[5px] group-hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out"
                >
                    <div class="pt-2xs">
                        <PostIcon
                            class="group-hover:fill-[var(--medium-primary)] transition-colors duration-500 ease-in-out"
                        />
                    </div>
                    Post Score
                </InertiaLink>
            </li> -->
            <li class="group flex justify-end">
                <InertiaLink
                    href="/players/me/rounds"
                    class="header-nav__player-name flex items-center gap-[5px] p-3 group-hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out"
                >
                    {{ playerName }}
                    <CaretDown class="group-hover:fill-[var(--medium-primary)] transition-colors duration-500 ease-in-out" />
                </InertiaLink>
                <AccountDropdown :expanded="false" class="top-[69px] z-[2]" />
            </li>
        </ul>
    </nav>
</template>

<script setup>
import SwingULogo from "@svg/swingu-white-logo.vue";
import CaretDown from "@components/app-swingu/svgs/caret-down.vue";
import AccountDropdown from "@components/app-swingu/account/account-dropdown.vue";
</script>

<script>
export default {
    components: {},
    props: {
        playerName: {
            type: String,
            default: "Unknown User",
        },
    },
};
</script>

<style scoped>
nav {
    @apply px-6 box-content pt-[20px] pb-[18px] items-baseline justify-between text-base font-medium text-white leading-none;
}
</style>
